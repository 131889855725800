import React from 'react';
import design from '../../assets/images/designer.svg';
import development from '../../assets/images/development.svg';
import consult from '../../assets/images/consult.svg';
import './services.scss';

const Services = () => (
    <section className="services" id="services">
        <summary>My offerings include</summary>
        <p>I offer following services across all business domains</p>
        <div className="services__list">
            <div className="services__item services__item--one">
                <img src={design} alt="designer" />
                <h4>UX UI Design</h4>
                <p className="services__description">
                    I always love to my designs minimal, <br />
                    clean, responsive & interactive.
                </p>
                <h3>I have designed</h3>
                <p className="services__skills">UX, UI, Web, Android, iOS, Wireframes</p>
                <h2>Design tools</h2>
                <p className="services__tools">Adobe XD</p>
            </div>
            <div className="services__item services__item--two">
                <img src={development} alt="development" />
                <h4>Full stack development</h4>
                <p className="services__description">
                    I enjoy bringing ideas to life which <br />
                    helps people in their business.
                </p>
                <h3>I can speak</h3>
                <p className="services__skills">HTML, CSS, JS, ReactJS, NodeJS, Java, SQL</p>
                <h2>Development tools</h2>
                <p className="services__tools">
                    Github, <br />
                    Bitbucket, <br />
                    NPM, <br />
                    Docker & Kubernetes
                </p>
            </div>
            <div className="services__item services__item--three">
                <img src={consult} alt="consult" />
                <h4>Consultation</h4>
                <p className="services__description">
                    I have always helped people by <br />
                    sharing the knowledge I have
                </p>
                <h3>I have consulted</h3>
                <p className="services__skills">Students, Startups, Businesses, Freelancers</p>
                <h2>Consultation media</h2>
                <p className="services__tools">
                    Case study, <br />
                    Live sessions, <br />
                    Bootcamps, <br />
                    Courses
                </p>
            </div>
        </div>
    </section>
);

export default Services;
