/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import image from '../../assets/images/desktop.svg';
import './header.scss';

const Header = () => (
    <header className="header" id="home">
        <div className="header__topbar">
            <span>Vaibhav.</span>
            <nav>
                <ul>
                    <li>
                        <a href="#">Home</a>
                    </li>
                    <li>
                        <a href="#about">About</a>
                    </li>
                    <li>
                        <a href="#services">Services</a>
                    </li>
                    <li>
                        <a href="#work">Work</a>
                    </li>
                    <li>
                        <a href="#testimonials">Testimonials</a>
                    </li>
                </ul>
            </nav>
            <div className="header__button">
                <button className="header__button--text" type="button">
                    <a href="mailto:bvaibhav.95@gmail.com" target="_blank" rel="noreferrer">
                        Let&apos;s Talk
                    </a>
                </button>
                <button className="header__button--icon" type="button">
                    <a href="mailto:bvaibhav.95@gmail.com" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faPhoneAlt} />
                    </a>
                </button>
            </div>
        </div>
        <div className="header__text">
            <h1>
                I&apos;m <span>Vaibhav</span>. I will create a modern UI UX design for your business
            </h1>
        </div>
        <div className="header__image">
            <img src={image} alt="Header Image" />
        </div>
    </header>
);

export default Header;
