import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faQuoteLeft } from '@fortawesome/free-solid-svg-icons';
import avatar from '../../assets/images/avatar.jpg';
import './testimonials.scss';

const Testimonials = () => (
    <section className="testimonials" id="testimonials">
        <summary>People say about me</summary>
        <p>People I&apos;ve worked with have said some nice things</p>
        <div className="testimonials__list">
            <button className="testimonials__left" type="button">
                <FontAwesomeIcon icon={faArrowLeft} />
            </button>
            <div className="testimonials__item">
                <FontAwesomeIcon icon={faQuoteLeft} />
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                </p>
                <img src={avatar} alt="avatar" />
                <h4>Jane Doe</h4>
                <span>Casper Limited</span>
            </div>
            <button className="testimonials__right" type="button">
                <FontAwesomeIcon icon={faArrowRight} />
            </button>
        </div>
    </section>
);

export default Testimonials;
