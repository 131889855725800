import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faQuoteLeft } from '@fortawesome/free-solid-svg-icons';
import ninja from '../../assets/images/ninja.svg';
import './about.scss';

const About = () => (
    <section className="about" id="about">
        <div className="about__intro">
            <h3>Hi, I&apos;m Vaibhav. Nice to meet you.</h3>
            <p>
                Since beginning my journey as a freelance designer nearly an year ago, I&apos;ve collaborated with few fantastic people to create digital products for both business and consumer use.
                I&apos;m quietly confident, naturally curious, and perpetually working on improving my chops one design problem at a time.
            </p>
        </div>
        <div className="about__quote">
            <h1>Let&apos;s work together. We should queue up a chat. I&apos;ll buy the coffee.</h1>
            <div className="about__quote__form">
                <input type="email" placeholder="Type your email here" />
                <button className="about__quote__form--text" type="button">
                    Get a quote
                </button>
                <button className="about__quote__form--icon" type="button">
                    <FontAwesomeIcon icon={faPaperPlane} />
                </button>
            </div>
        </div>
        <div className="about__ninja">
            <div className="about__ninja__content">
                <h3>Teaching myself few</h3>
                <h4>Ninja Techniques</h4>
                <div className="about__ninja__description">
                    <span className="quote">
                        <FontAwesomeIcon icon={faQuoteLeft} />
                    </span>
                    <summary>
                        <span>
                            &quot;Lorem ipsum dolor sit amet, laboris nisi ut aliquip ex ea co consectetur adipiscing elit, sed do laboris nisi ut aliquip ex ea co eiusmod tempor incididunt ut labore
                            et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud laboris nisi ut aliquip ex ea co exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis
                            aute irure dolor in&quot;
                            <br />
                            <br />
                            &quot;Lorem ipsum dolor sit amet, consectetur dolor sit amet adipiscing dolor sit amet sit amet adipiscing enim ad elit, sed do consectetur dolor sit amet eiusmod
                            consectetur dolor consectetur dolor sit amet sit amet tempor incididunt ut labore et dolore magna aliqua. Ut consectetur dolor sit amet sit amet adipiscing enim ad minim
                            veniam&quot;
                        </span>
                    </summary>
                </div>
            </div>
            <div className="about__ninja__image">
                <img src={ninja} alt="Ninja" />
            </div>
        </div>
    </section>
);

export default About;
