/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebookF, faInstagram, faLinkedinIn, faDribbble, faBehance } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faHeart } from '@fortawesome/free-solid-svg-icons';
import './footer.scss';

const Footer = () => (
    <section className="footer" id="footer">
        <h2>Vaibhav.</h2>
        <span>Helping, learning & climbing one step everyday</span>
        <div className="footer__social">
            <a href="https://twitter.com/inf_wavelength" target="_blank">
                <div className="footer__social__item">
                    <FontAwesomeIcon icon={faTwitter} />
                </div>
            </a>
            <a href="https://www.instagram.com/infinite.wavelength" target="_blank">
                <div className="footer__social__item">
                    <FontAwesomeIcon icon={faInstagram} />
                </div>
            </a>
            <a href="https://www.facebook.com/vaibhav.bacchav.3" target="_blank">
                <div className="footer__social__item">
                    <FontAwesomeIcon icon={faFacebookF} />
                </div>
            </a>
            <a href="https://www.linkedin.com/in/vaibhav-kb/" target="_blank">
                <div className="footer__social__item">
                    <FontAwesomeIcon icon={faLinkedinIn} />
                </div>
            </a>
            <a href="https://dribbble.com/bvaibhav95" target="_blank">
                <div className="footer__social__item">
                    <FontAwesomeIcon icon={faDribbble} />
                </div>
            </a>
            <a href="https://www.behance.net/vaibhavbachhav" target="_blank">
                <div className="footer__social__item">
                    <FontAwesomeIcon icon={faBehance} />
                </div>
            </a>
            <a href="mailto:bvaibhav.95@gmail.com" target="_blank">
                <div className="footer__social__item">
                    <FontAwesomeIcon icon={faEnvelope} />
                </div>
            </a>
        </div>
        <h4>Handcrafted by me</h4>
        <div className="footer__made">
            <span>Made with</span> <FontAwesomeIcon icon={faHeart} />
        </div>
    </section>
);

export default Footer;
