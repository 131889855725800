import React from 'react';
import work1 from '../../assets/images/work-1.png';
import work2 from '../../assets/images/work-2.png';
import work3 from '../../assets/images/work-3.png';
import work4 from '../../assets/images/work-4.png';
import work5 from '../../assets/images/work-5.png';
import work6 from '../../assets/images/work-6.png';
import './work.scss';

const Work = () => (
    <section className="work" id="work">
        <summary>What I&apos;m working on</summary>
        <p>Check my recent and on-going work</p>
        <div className="work__list">
            <div className="work__item work__item--one">
                <div className="work__image">
                    <div className="work__image__overlay">
                        <button type="button">View Project</button>
                    </div>
                    <img src={work1} alt="project" />
                </div>
                <h4>Travel application</h4>
                <span>Mobile design</span>
            </div>
            <div className="work__item work__item--two">
                <div className="work__image">
                    <div className="work__image__overlay">
                        <button type="button">View Project</button>
                    </div>
                    <img src={work2} alt="project" />
                </div>
                <h4>Storage tracking application</h4>
                <span>Mobile design</span>
            </div>
            <div className="work__item work__item--three">
                <div className="work__image">
                    <div className="work__image__overlay">
                        <button type="button">View Project</button>
                    </div>
                    <img src={work3} alt="project" />
                </div>
                <h4>E-commerce application</h4>
                <span>Mobile design</span>
            </div>
            <div className="work__item work__item--four">
                <div className="work__image">
                    <div className="work__image__overlay">
                        <button type="button">View Project</button>
                    </div>
                    <img src={work4} alt="project" />
                </div>
                <h4>Online Coach application</h4>
                <span>Mobile design</span>
            </div>
            <div className="work__item work__item--five">
                <div className="work__image">
                    <div className="work__image__overlay">
                        <button type="button">View Project</button>
                    </div>
                    <img src={work5} alt="project" />
                </div>
                <h4>Home Training application</h4>
                <span>Mobile design</span>
            </div>
            <div className="work__item work__item--six">
                <div className="work__image">
                    <div className="work__image__overlay">
                        <button type="button">View Project</button>
                    </div>
                    <img src={work6} alt="project" />
                </div>
                <h4>Food Recipe application</h4>
                <span>Mobile design</span>
            </div>
        </div>
    </section>
);

export default Work;
