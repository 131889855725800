import React from 'react';
import Header from '../components/header/header';
import About from '../components/about/about';
import Services from '../components/services/services';
import Work from '../components/work/work';
import Testimonials from '../components/testimonials/testimonials';
import Footer from '../components/footer/footer';
import './home.scss';

// TODO: write media queries properly

const Home = () => (
    <>
        <Header />
        <About />
        <Services />
        <Work />
        <Testimonials />
        <Footer />
    </>
);

export default Home;
